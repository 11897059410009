import React from "react"
// import {  } from '@builder.io/gatsby';
import { graphql } from "gatsby"
import { builder, BuilderComponent } from "@builder.io/react"
import Header from "../components/Header"
import Footer from "../components/Footer"

builder.init("644b3df34c7d4cec94bcd533ff27ac50")

const BlogPage = props => {
  console.log(props)
  const content = props.data?.allBuilderModels.blog[0]?.content

  return (
    <React.Fragment>
      {" "}
      <Header />
      <BuilderComponent content={content} model="blog" />
      <Footer />{" "}
    </React.Fragment>
  )
}
export default BlogPage

export const query = graphql`
  query($path: String!) {
    allBuilderModels {
      blog(target: { urlPath: $path }, limit: 1, options: { cachebust: true }) {
        content
      }
    }
  }
`
